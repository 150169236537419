<template>
  <v-container fluid>
    <PageNavigation :items="items" />
    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.sysFuncs')"
          icon="mdi-apple-finder"
        >
          <v-row>
            <v-col cols="12" lg="3" md="6" sm="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6">
              <v-select
                :label="$vuetify.lang.t('$vuetify.selecttype')"
                :items="cloneCategory"
                :item-text="(item) => item.name"
                @change="(item) => handleCategory(item)"
                return-object
                clearable
              />
            </v-col>

            <v-spacer />

            <v-col
              cols="12"
              lg="4"
              class="d-flex justify-end align-center"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              <v-btn color="green" @click="categoryDialog = true">
                <v-icon left>mdi-plus</v-icon>
                New Category
              </v-btn>

              <v-btn color="green" elevation="2" @click="handleDialog">
                <v-icon left>mdi-account-plus </v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-col>

            <v-col cols="12" class="d-flex justify-end align-center" v-else>
              <v-btn color="green" @click="categoryDialog = true">
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <v-btn color="green" elevation="2" @click="handleDialog">
                <v-icon>mdi-account-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <Functable
                :head="headers"
                :data="datas"
                :search="search"
                v-on:edititem="handleDialogEdit"
                v-on:deleteitem="handleDeleteDialog"
              />
            </v-col>
          </v-row>
        </base-material-card>

        <FuncCRUD
          :dialog.sync="dialog"
          @handledata="handleFunc"
          :myObj="obj"
          :category="category"
        />
        <DialogDelete
          :dialogDelete.sync="dialogDelete"
          :deleteObj="deleteObj"
          @handleDelete="handleDeleteConfirm"
        />
        <FuncCategory
          :categoryDialog.sync="categoryDialog"
          :cloneCategory="cloneCategory"
          :categoryObj="categoryObj"
          :category="category"
          @handleDone="handleDone"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//FIXME Able to upsert, edit but issues with v-combo-box in console

import {
  functionList,
  functionTypesList,
  functionDel,
  functionUpsert,
} from "@/api/system";

const newObj = () => {
  return {
    id: "",
    name: "",
    code: "",
    type_code: "",
    list: false,
    add: false,
    del: false,
    modify: false,
    row: false,
    row_fields: "",
    column: false,
    column_fields: "",
  };
};

const newSearch = () => {
  return {
    type_code: "",
    skip: 0,
    limit: 100,
    key: "",
  };
};

export default {
  name: "func",
  components: {
    Functable: () => import("@/components/system/Functable"),
    FuncCRUD: () => import("@/components/system/FuncCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    FuncCategory: () => import("@/components/system/FuncCategory"),
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      categoryDialog: false,
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.sysMgr") },
        { text: this.$vuetify.lang.t("$vuetify.sysFuncs") },
      ],
      datas: [],
      search: "",
      obj: newObj(),
      deleteObj: {},
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.name"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.ID"),
          value: "code",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.code"),
          value: "type_code",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.toView"),
          value: "list",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.add"),
          value: "add",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.delete"),
          value: "del",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.modify"),
          value: "modify",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Colum"),
          value: "column",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.row"),
          value: "row",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "80",
        },
      ],
      category: [],
      categoryType: "",
      selectedItem: [
        {
          text: this.$vuetify.lang.t("$vuetify.categoryListAll"),
          value: "all",
        },
      ],
      categoryObj: {
        code: "",
        id: "",
        items: null,
        name: "",
        order: 0,
        parent: "",
      },
      cloneCategory: [],
      searchForm: newSearch(),
      updataData: {
        id: "",
        name: "",
        code: "",
        type_code: "",
        list: false,
        add: false,
        del: false,
        modify: false,
        row: false,
        row_fields: "",
        column: false,
        column_fields: "",
      },
    };
  },
  methods: {
    getData() {
      this.cloneCategory = [];
      this.category = [];

      functionList(this.searchForm)
        .then((data) => {
          if (data.items != null) {
            this.datas = [...data.items];
            for (var i = 0; i < this.datas.length; i++) {
              this.datas[i].itemNo = i + 1;
            }
          } else {
            this.datas = [];
            this.$toast.info("No Data Found");
          }
        })
        .catch((err) => console.log(err));

      functionTypesList().then((res) => {
        this.cloneCategory = [...res.items];
        this.category.push("All");
        res.items.forEach((p) => this.category.push(p.name));
      });
    },
    handleCategory(item) {
      if (item != null) {
        this.searchForm.type_code = item.code;
      } else {
        this.searchForm = newSearch();
      }

      this.getData();
    },
    handleDone() {
      this.getData();
    },
    handleFunc(obj) {
      this.dialog = false;

      for (let i = 0; i < this.cloneCategory.length; i++) {
        if (this.cloneCategory[i].name === obj.categoryName) {
          obj.type_code = this.cloneCategory[i].code;
        }
      }
      obj.add = obj.add;
      obj.code = obj.code;
      obj.column = obj.column;
      obj.del = obj.del;
      obj.id = obj.id;
      obj.list = obj.list;
      obj.modify = obj.modify;
      obj.name = obj.name;
      obj.row = obj.row;
      obj.type_code = obj.type_code;
      obj.column_fields = JSON.stringify(obj.column_fields);
      obj.row_fields = JSON.stringify(obj.row_fields);

      functionUpsert(obj)
        .then(() => {
          this.getData();
        })
        .catch((err) => console.log("New Func: ", err));
    },
    handleDialog() {
      this.dialog = true;
      this.obj = newObj();
    },
    handleDialogEdit(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.obj = obj;
        for (var i = 0; i < this.cloneCategory.length; i++) {
          if (this.cloneCategory[i].code === this.obj.type_code) {
            var categoryName = this.cloneCategory[i].name;
            this.obj.categoryName = categoryName;
          }
        }
      }
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;
      functionDel(obj.id)
        .then(() => {
          this.datas.splice(this.datas.indexOf(obj), 1);
        })
        .catch((err) => console.log("Function Delete Error", err));
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    dialog() {
      this.getData();
    },
  },
};
</script>

<style>
</style>